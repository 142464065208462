#grid_wrapper {
  position: relative;
}

#top_pager {
  & > div {
    width: 100%;
  }
}

.k-grid {
  & tbody tr {
    height: 50px;
  }
  & .k-header {
    white-space: unset;
    & .k-link {
      overflow: unset;
    }
    & .k-column-title {
      text-overflow: unset;
      white-space: unset;
      overflow: unset;
    }
    & .k-grid-filter {
      align-self: flex-end;
    }
  }
}

.k-grid.groupable_table {
  & > .k-grid-header {
    & thead {
      & tr:first-child {
        & .k-cell-inner {
          justify-content: center;
        }
        & .k-column-title {
          font-size: 15px;
          font-weight: bold;
          & span {
            font-size: 1rem;
            font-weight: initial;
          }
        }
      }
    }
  }
  & .k-hierarchy-cell {
    position: sticky;
    z-index: 3;
    left: 0;
    right: 0;
  }

  & > .k-grid-pager,
  & > .k-grid-toolbar,
  & > .k-grid-header,
  & > .k-grouping-header {
    position: sticky;
    z-index: $zindex-table-sticky;
  }

  & > .k-grid-toolbar {
    height: 97px;
    top: 0;
  }

  & > .k-grouping-header {
    height: 44px;
    top: 97px; // this is the height of .k-grid-toolbar
  }
  & > .k-grid-header {
    top: 141px; // this is the height of .k-grid-toolbar + .k-grouping-header
  }

  & > .k-grid-pager {
    bottom: 0;
  }
}

.k-button-lg {
  padding: 0.8rem 1.6rem;
  font-size: 1.1rem;
  font-weight: 600;
}
