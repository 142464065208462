.navbar-main {
  .k-searchbar {
    .k-input {
      font-weight: $font-weight-bold;

      &::placeholder {
        font-weight: $font-weight-base;
      }
    }
  }

  .k-menu {
    &.k-menu-horizontal {
      padding: 0;
    }

    background: transparent;

    .k-link {
      color: $white;

      &.k-state-active {
        background: $primary-light-1;
      }
    }
  }

  .k-dropdown-wrap {
    border-color: transparent;
  }

  .input-group-prepend,
  .input-group-text {
    border-color: transparent;
  }

  .form-control,
  .custom-select {
    border-color: transparent;
  }

  .nav-link {
    &.active {
      background-color: $secondary;
    }
  }

  .nav-link {
    .fa {
      margin-right: $spacer/2;
    }
  }
}

.navbar-brand {
  display: flex;
  align-items: center;

  .img-logo {
    height: $spacer * 2;
  }

  .tagline {
    font-size: $h4-font-size;
    font-weight: $font-weight-light;
    line-height: $headings-line-height;
    text-transform: uppercase;
    margin: auto $spacer;
    white-space: normal;
    border-left: 1px solid $secondary;
    padding: $spacer/2 $spacer/2 $spacer/2 $spacer;
  }
}

.nav-actions {
  margin: $spacer auto;
}

.navbar-footer {
  font-size: $font-size-sm;

  .nav-link {
    padding-top: $spacer/2;
    padding-bottom: $spacer/2;
  }
}
