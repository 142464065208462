/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
//  @import "~bootstrap/scss/variables";
//  @import "~bootstrap/scss/utilities";
@import "assets/scss/base";
@import "assets/scss/colors";
@import "assets/scss/variables";
@import "~@progress/kendo-theme-bootstrap/dist/all";
@import "~bootstrap/scss/bootstrap.scss";
// @import "~@progress/kendo-theme-bootstrap/scss/mixins";
// @import "~@progress/kendo-theme-bootstrap/scss/root";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/reboot";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/type";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/images";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/code";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/grid";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/tables";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/forms";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/buttons";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/transitions";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/dropdown";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/button-group";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/input-group";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/custom-forms";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/nav";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/navbar";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/card";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/breadcrumb";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/pagination";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/badge";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/jumbotron";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/alert";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/progress";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/media";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/list-group";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/close";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/toasts";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/modal";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/tooltip";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/popover";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/carousel";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/spinners";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/utilities";
// @import "~@progress/kendo-theme-bootstrap/modules/bootstrap/scss/print";

$lato-font-path: "~lato-font/fonts" !default;
@import "~lato-font/scss/lato-font";

/* Custom SCSS assets */
@import "assets/scss/spinner";
@import "assets/scss/nav";
@import "assets/scss/kendo";
