$primary: #26a89d;
$primary-light-1: #71dbd1;
$primary-light-2: #9ee8e1;
$primary-light-3: #cff2f0;
$primary-dark-1: #1f7d72;
$primary-dark-2: #13544e;
$primary-dark: darken($primary, 10%);

$secondary: #ffb611;
$secondary-light-1: #ffc929;
$secondary-light-2: #ffe081;
$secondary-light-3: #ffedb2;
$secondary-dark-1: #ff9000;
$secondary-dark-2: #ff7000;
$secondary-dark: darken($secondary, 10%);

$neutral: #f7f7f7;
$neutral-light-1: #bdbdbd;
$neutral-light-2: #e0e0e0;
$neutral-light-3: #f2f2f2;
$neutral-dark-1: #616161;
$neutral-dark-2: #424242;

$flat-rate: #219ebc;
